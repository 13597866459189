<template>
  <div class="tabs-block">
    <slot name="before"></slot>
    <div class="tabs-header">
      <div
        v-for="item in headerTabItems"
        :key="item.identifier"
        @click="activateTab(item.identifier)"
        class="tab"
        :class="{'is-active': item.identifier === tabActiveId, [item.identifier]: true}"
      >
        <component v-if="item.icon" :is="item.icon" :size="20"></component>
        <div>{{ getItemName(item) }}</div>
      </div>
    </div>

    <div class="tabs-content stand-out">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import sortBy from 'lodash/sortBy';
import AiIcon from 'vue-material-design-icons/Creation';

export default {
  props: {
    startTab: String,
  },
  data() {
    return {
      tabItems: {},
      tabActiveId: '',
      tabsContentRef: 'tabs-content',
    };
  },
  computed: {
    isMobile() {
      return this.$store.getters.windowWidthState <= this.$breakpoints.tablet;
    },
    headerTabItems() {
      const values = Object.values(this.tabItems).filter(item => item.visible);
      if (values.length && values[0].priority) {
        return sortBy(values, 'priority');
      }
      return values;
    },
  },
  methods: {
    register(tabItemInstance) {
      if (this.isTabItemComponent(tabItemInstance.$options)) {
        this.tabItems = {
          ...this.tabItems,
          [tabItemInstance.identifier]: this.tabItems[tabItemInstance.identifier] || tabItemInstance,
        };
        if (!this.tabActiveId && (!this.startTab || this.startTab === tabItemInstance.identifier)) {
          this.activateTab(tabItemInstance.identifier);
        }
      }
    },
    isTabItemComponent(options) {
      return options && options.name === 'TabsBlockItem';
    },
    activateTab(identifier) {
      if (this.tabActiveId === identifier) {
        return;
      }
      const fromTabId = this.tabActiveId;
      this.tabActiveId = identifier;
      for (let tab of Object.values(this.tabItems)) {
        tab.setIsActive(tab.identifier === this.tabActiveId);
      }
      this.$emit('activate-tab', {tabId: this.tabActiveId, fromTabId});
    },
    getItemName(item) {
      return this.isMobile && item.nameMobile ? item.nameMobile : item.name;
    },
  },
  components: {
    AiIcon,
  },
  name: 'TabsBlock',
};
</script>

<style lang="scss" scoped>
.stand-out {
  background-color: $background-light;
  border-radius: 4px;
  padding: 24px 20px 32px;

  @media only screen and (max-width: $breakpoint-tablet) {
    padding: 16px;
  }
}

.tabs-block {
  .tabs-header {
    display: flex;

    .tab {
      padding: 10px;
      font-weight: 400;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      color: $neutral-500;
      z-index: 1;
      cursor: pointer;
      margin-right: 15px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .material-design-icon {
        color: $primary-600;
        margin-right: 10px;
      }

      &.is-active {
        background-color: $background-light;
        font-weight: 800;
        z-index: 3;
        color: $neutral-600;
        border-bottom: 2px solid $primary-400;
      }
    }
  }
}
</style>
