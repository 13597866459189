<template>
  <div class="ai-search-query text-md" v-show="aiSearchQueryState">
    <div>
      {{ aiSearchQueryState }}
    </div>
    <div class="edit-button" @click="$emit('edit')">
      <edit-icon :size="20"></edit-icon>
    </div>
  </div>
</template>

<script>
import EditIcon from 'vue-material-design-icons/Pencil';
import {mapGetters} from 'vuex';

export default {
  computed: {
    ...mapGetters(['aiSearchQueryState']),
  },
  components: {EditIcon},
  name: 'AiSearchQuery',
};
</script>

<style scoped lang="scss">
.ai-search-query {
  word-break: break-word;
  margin-bottom: 12px;
  color: $neutral-800;
  font-weight: 600;
  display: flex;
  gap: 12px;

  .edit-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $neutral-600;
  }
}

@media only screen and (max-width: $breakpoint-tablet) {
  .ai-search-query {
    display: none;
  }
}
</style>
