<template>
  <div class="my-orders-page">
    <div class="limited_content">
      <div class="title-wrapper">
        <div class="bold">Record Inbox</div>
        <div><router-link :to="searchRecordsRoute">+ Search for Records</router-link></div>
      </div>

      <mcr-loading-indicator v-if="familyTreeSavedMentionsLoadingState" :loading="true"></mcr-loading-indicator>
      <div v-else-if="noRecords">No saved saved records.</div>
      <div v-else>
        <base-pagination-meta :meta="familyTreeSavedMentionsMetaState" items-name="records"></base-pagination-meta>
        <saved-record-item
          v-for="record in familyTreeSavedMentionsState"
          :key="record.id"
          :record="record"
          @reload-records="getSavedMentions"
        ></saved-record-item>
        <base-pagination
          :meta="familyTreeSavedMentionsMetaState"
          :show-jump-to="false"
          @onSwitchPage="getSavedMentions"
        ></base-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import BasePagination from '@common/elements/BasePagination.vue';
import BasePaginationMeta from '@common/elements/BasePaginationMeta.vue';
import {TAB_ID_AI_SEARCH, TAB_ID_INDEXED_RECORDS} from '@common/utils/consts.search';
import {mapGetters} from 'vuex';

import SavedRecordItem from '@/components/modules/myRecordInbox/SavedRecordItem';

export default {
  metaInfo: {
    title: 'Record Inbox',
  },
  created() {
    this.getSavedMentions(1);
  },
  computed: {
    ...mapGetters([
      'familyTreeSavedMentionsState',
      'familyTreeSavedMentionsMetaState',
      'familyTreeSavedMentionsLoadingState',
    ]),
    noRecords() {
      return this.familyTreeSavedMentionsMetaState.total_count === 0;
    },
    searchRecordsRoute() {
      const tab = this.$store.getters.featureSwitchesState.SEARCH_AI ? TAB_ID_AI_SEARCH : TAB_ID_INDEXED_RECORDS;
      return {name: 'search-all-records', query: {tab: tab}};
    },
  },
  methods: {
    getSavedMentions(page) {
      this.$store.dispatch('fetchSavedMentionsAction', {page});
    },
  },
  components: {SavedRecordItem, BasePagination, BasePaginationMeta},
  name: 'MyRecordsPage',
};
</script>

<style lang="scss" scoped>
.title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
</style>
