<template>
  <div class="search-results-empty" :class="{'no-results': !resultsCount}">
    <div v-if="indexedSearch && name" class="results-empty-wrapper">
      <book-icon :size="50" v-if="!resultsCount"></book-icon>
      <h4 v-if="!resultsCount">No indexed record matches for "{{ name }}"</h4>
      <div class="text-lg">
        🔍 Tweak your search for better results!
        <router-link :to="wikiArticleRoute" target="_blank">Learn how</router-link>
      </div>
      <div class="text-lg divided">
        There could be matches for "{{ name }}" using full text search,
        <router-link :to="fullTextSearchRoute">try now?</router-link>
      </div>
    </div>
    <div v-else-if="fullTextSearch && name" class="results-empty-wrapper">
      <book-icon :size="50" v-if="!resultsCount"></book-icon>
      <h4 v-if="!resultsCount">No full text matches for "{{ name }}"</h4>
      <div class="text-lg">
        Because the optical character recognition technology used to transcribe records it not always accurate, there
        could still be matches for "{{ name }}"
      </div>

      <div class="suggestion">
        <div class="bold">Why not try:</div>
        <ul v-if="isChineseInput">
          <li>Adjusting your search terms</li>
          <li>Searching for "{{ name }}" in <a @click="runIndexedSearch">indexed records</a></li>
        </ul>
        <ul v-else>
          <li>Searching for "{{ name }}" in <a @click="runIndexedSearch">indexed records</a></li>
          <li>Using the Chinese characters for "{{ name }}" in <a @click="openFullTextForm">full text</a></li>
        </ul>
      </div>
    </div>
    <div v-else-if="sourcesSearch && !resultsCount" class="results-empty-wrapper">
      <book-icon :size="50"></book-icon>
      <h4>No sources found for your search</h4>
      <div class="text-lg">
        Try adjusting your filters: <a @click="onEditSearchClick">Edit Search</a><span>, or </span
        ><a @click="onNewSearchClick">New Search</a>
      </div>
    </div>

    <div v-else-if="!resultsCount" class="results-empty-wrapper">
      <book-icon :size="50"></book-icon>
      <h4 v-if="!resultsCount">No results found for your search</h4>
      <div class="text-lg">
        Try adjusting your filters: <a @click="onEditSearchClick">Edit Search</a><span>, or </span
        ><a @click="onNewSearchClick">New Search</a>
      </div>
    </div>
    <slot name="cta"></slot>
  </div>
</template>

<script>
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';
import {TAB_ID_FULL_TEXT, TAB_ID_INDEXED_RECORDS, TAB_ID_SOURCES} from '@common/utils/consts.search';
import {isChineseText} from '@common/utils/utils';
import BookIcon from 'vue-material-design-icons/BookSearchOutline';
import {mapGetters} from 'vuex';

export default {
  props: {
    activeTab: String,
    resultsCount: Number,
    firstName: String,
    lastName: String,
  },
  data() {
    return {
      registerLabel: 'sign up',
    };
  },
  computed: {
    ...mapGetters(['userIsLoggedInState']),
    registerRoute() {
      return {name: 'register', query: {redirect: this.$route.fullPath}};
    },
    indexedSearch() {
      return this.activeTab === TAB_ID_INDEXED_RECORDS;
    },
    fullTextSearch() {
      return this.activeTab === TAB_ID_FULL_TEXT;
    },
    sourcesSearch() {
      return this.activeTab === TAB_ID_SOURCES;
    },
    name() {
      return this.lastName && this.firstName ? `${this.lastName}, ${this.firstName}` : this.lastName || this.firstName;
    },
    isChineseInput() {
      return this.name ? isChineseText(this.name) : false;
    },
    fullTextSearchRoute() {
      return {
        name: 'search-all-records',
        query: {tab: TAB_ID_FULL_TEXT, q: this.firstName, last_name: this.lastName},
      };
    },
    wikiArticleRoute() {
      return {name: 'wiki-article', params: {slug: 'chinese-genealogy-intro'}};
    },
  },
  methods: {
    onNewSearchClick() {
      this.$router.push({query: {tab: this.$route.query.tab}});
    },
    onEditSearchClick() {
      this.$emit('edit-search');
    },
    trackPreRegisterAction() {
      AnalyticsMainHandler.trackPreRegisterActionLinkClick(getRoutePageName(this.$route), this.registerLabel);
    },
    openFullTextForm() {
      if (this.$route.query.tab === TAB_ID_FULL_TEXT) {
        this.onEditSearchClick();
        return;
      }
      this.$router.push({name: 'search-all-records', query: {tab: TAB_ID_FULL_TEXT}});
    },
    runIndexedSearch() {
      this.$router.push({
        name: 'search-all-records',
        query: {tab: TAB_ID_INDEXED_RECORDS, first_name: this.firstName, surname: this.lastName},
      });
    },
  },
  components: {BookIcon},
};
</script>

<style lang="scss" scoped>
.search-results-empty {
  margin-top: 20px;
  margin-bottom: 16px;
  .results-empty-wrapper {
    display: flex;
    flex-direction: column;
    word-break: break-word;

    .material-design-icon {
      color: $neutral-500;
    }

    h4 {
      margin: 20px 0 12px;
    }

    .text-lg {
      color: $neutral-500;
    }
    .text-lg + .text-lg {
      margin-top: 8px;
    }
    .text-lg.divided {
      border-top: 1px solid $neutral-300;
      padding-top: 16px;
      margin-top: 16px;
    }

    .suggestion {
      align-self: flex-start;
      text-align: start;
      margin-top: 16px;
      ul {
        margin: 9px 0 40px;
      }
    }
  }

  &.no-results .results-empty-wrapper {
    align-items: center;
    text-align: center;
    min-height: 250px;

    .text-lg {
      color: $neutral-600;
    }
  }

  @media only screen and (max-width: $breakpoint-tablet) {
    .results-empty-wrapper {
      h4 {
        margin: 16px 0 8px;
      }
    }

    &.no-results .results-empty-wrapper {
      min-height: 100px;
      margin-top: 36px;
      margin-bottom: 60px;
    }
  }
}
</style>
