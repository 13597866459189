<template>
  <tabs-block ref="tabs" :start-tab="startTab" :class="mainClasses" @activate-tab="onActivateTab">
    <template v-slot:before>
      <div class="mobile-switch" v-if="featureSwitchesState.SEARCH_AI" key="mobile-switch">
        <div class="tab" :class="{'is-active': isTabAISearch}" @click="forceActivateTab(TAB_ID_AI_SEARCH)">
          <ai-icon :size="20"></ai-icon><span>AI Search</span>
        </div>
        <div class="tab" :class="{'is-active': !isTabAISearch}" @click="forceActivateTab(defaultStandardTab)">
          Standard
        </div>
      </div>
    </template>
    <template v-slot:default>
      <tabs-block-item
        :visible="!!featureSwitchesState.SEARCH_AI"
        name="AI Search"
        :identifier="TAB_ID_AI_SEARCH"
        :key="TAB_ID_AI_SEARCH"
        :priority="1"
        icon="ai-icon"
      >
        <ai-search-form @submit="onSubmitAiSearch"></ai-search-form>
      </tabs-block-item>
      <tabs-block-item
        :visible="showAllTabs || searchCategoryState.has_indexed_form"
        name="Indexed Records"
        name-mobile="Indexed"
        :identifier="TAB_ID_INDEXED_RECORDS"
        :key="TAB_ID_INDEXED_RECORDS"
        :priority="2"
      >
        <div class="beta-disclaimer supplemental" v-if="isZupusCategory">
          Indexed search for zupus is in beta. Use <a @click="goToFullTextTab">Full Text Search</a> for full coverage of
          zupus.
        </div>
        <indexed-records-form
          ref="indexed-records-form"
          :init-advanced="initAdvanced"
          :init-lang="isCategoryPage ? searchCategoryState.default_language : 'en'"
          :show-cancel="showCancel"
          :show-lang-selector="!isZupusCategory"
          :is-simple-form-small="isZupusCategory"
          :clear-form="clearForms"
          @submit="onSubmit({tab: TAB_ID_INDEXED_RECORDS})"
          @validation-error="onValidationError"
          @cancel="onCancel"
        ></indexed-records-form>
        <slot></slot>
      </tabs-block-item>
      <tabs-block-item
        :visible="showAllTabs || searchCategoryState.has_full_text_form"
        name="Full Text"
        :identifier="TAB_ID_FULL_TEXT"
        :key="TAB_ID_FULL_TEXT"
        :priority="3"
      >
        <full-text-form
          ref="full-text-form"
          :init-advanced="initAdvanced"
          :init-lang="isCategoryPage ? searchCategoryState.default_language : 'en'"
          :show-cancel="showCancel"
          :show-lang-selector="!isZupusCategory"
          :clear-form="clearForms"
          @submit="onSubmit({tab: TAB_ID_FULL_TEXT})"
          @validation-error="onValidationError"
          @cancel="onCancel"
        ></full-text-form>
        <slot></slot>
      </tabs-block-item>
      <tabs-block-item
        :visible="showAllTabs || searchCategoryState.has_sources_form"
        :name="sourcesTabName"
        :identifier="TAB_ID_SOURCES"
        :key="TAB_ID_SOURCES"
        :priority="4"
      >
        <sources-form
          ref="sources-form"
          :init-form-data="searchAllSourcesFormState"
          :meta="searchAllSourcesOptionsState.facets"
          :source-types="sourceTypes"
          :init-advanced="initAdvanced"
          :show-cancel="showCancel"
          :is-zupus-category="isZupusCategory"
          :clear-form="clearForms"
          @submit="onSubmit({form: $event, tab: TAB_ID_SOURCES})"
          @validation-error="onValidationError"
          @cancel="onCancel"
        ></sources-form>
        <slot></slot>
      </tabs-block-item>
    </template>
  </tabs-block>
</template>

<script>
import TabsBlock from '@common/elements/tabs/TabsBlock';
import TabsBlockItem from '@common/elements/tabs/TabsBlockItem';
import AiSearchForm from '@common/pages/searches/forms/AiSearchForm';
import SourcesForm from '@common/pages/searches/forms/SourcesForm';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';
import {TAB_ID_FULL_TEXT, TAB_ID_INDEXED_RECORDS, TAB_ID_SOURCES, TAB_ID_AI_SEARCH} from '@common/utils/consts.search';
import {sourceTypeCategories} from '@common/utils/consts.source';
import AiIcon from 'vue-material-design-icons/Creation';
import {mapGetters} from 'vuex';

import FullTextForm from '../forms/FullTextForm';
import IndexedRecordsForm from '../forms/IndexedRecordsForm';

export default {
  props: {
    startTab: String,
    initAdvanced: {type: Boolean, default: false},
    showCancel: {type: Boolean, default: false},
    showAllTabs: {type: Boolean, default: false},
    clearForms: {type: Boolean, default: false},
  },
  data() {
    return {
      TAB_ID_INDEXED_RECORDS,
      TAB_ID_FULL_TEXT,
      TAB_ID_SOURCES,
      TAB_ID_AI_SEARCH,
      activeTab: this.startTab,
    };
  },
  computed: {
    ...mapGetters([
      'searchAllRecordsOptionsState',
      'searchAllSourcesFormState',
      'searchAllSourcesOptionsState',
      'searchCategoryState',
      'featureSwitchesState',
    ]),
    fromFullTextToIndexedFields() {
      return {
        ...this.formFiltersShared,
        q: 'first_name',
        last_name: 'surname',
        // country: 'country_id',
        // source_type: 'source_types',
      };
    },
    getValueMapping() {
      return {
        // country: this.getCountryName,
        // country_id: this.getCountryId,
      };
    },
    fromIndexedToFullTextFields() {
      let ret = {...this.formFiltersShared};
      for (let key in this.fromFullTextToIndexedFields) {
        ret[this.fromFullTextToIndexedFields[key]] = key;
      }
      return ret;
    },
    formFiltersShared() {
      return {category_id: 'category_id'};
    },
    sourceTypes() {
      return this.searchAllSourcesOptionsState.facets ? this.searchAllSourcesOptionsState.facets.source_type : null;
    },
    isCategoryPage() {
      return this.searchCategoryState && this.$route.query.category_id === this.searchCategoryState.id;
    },
    isZupusCategory() {
      return this.$route.query.category_id === sourceTypeCategories.CATEGORY_ZUPUS;
    },
    isTabAISearch() {
      return this.activeTab === TAB_ID_AI_SEARCH;
    },
    sourcesTabName() {
      return this.isZupusCategory ? 'Books' : 'Sources';
    },
    mainClasses() {
      const tab = this.activeTab;
      return tab ? [tab] : [];
    },
    defaultStandardTab() {
      if (this.showAllTabs || this.searchCategoryState.has_indexed_form) {
        return TAB_ID_INDEXED_RECORDS;
      }
      return TAB_ID_SOURCES;
    },
  },
  methods: {
    onActivateTab({tabId, fromTabId}) {
      this.trackActivateTab(tabId);
      this.activeTab = tabId;
      this.$emit('activate-tab', {tabId, fromTabId});
      if (!fromTabId || !tabId) {
        return;
      }
      if (fromTabId === this.TAB_ID_AI_SEARCH || tabId === this.TAB_ID_AI_SEARCH) {
        return;
      }
      if (tabId === this.TAB_ID_FULL_TEXT && fromTabId === this.TAB_ID_INDEXED_RECORDS) {
        return this.mutateTabForm(
          this.fromIndexedToFullTextFields,
          this.$refs['indexed-records-form'].formData,
          this.$refs['full-text-form']
        );
      }
      if (tabId === this.TAB_ID_INDEXED_RECORDS && fromTabId === this.TAB_ID_FULL_TEXT) {
        return this.mutateTabForm(
          this.fromFullTextToIndexedFields,
          this.$refs['full-text-form'].formData,
          this.$refs['indexed-records-form']
        );
      }
      return this.mutateTabForm(this.formFiltersShared, this.getRef(fromTabId).formData, this.getRef(tabId));
    },
    forceActivateTab(tabId) {
      this.$refs.tabs.activateTab(tabId);
    },
    getRef(tabId) {
      const mapping = {
        [TAB_ID_INDEXED_RECORDS]: this.$refs['indexed-records-form'],
        [TAB_ID_FULL_TEXT]: this.$refs['full-text-form'],
        [TAB_ID_SOURCES]: this.$refs['sources-form'],
        [TAB_ID_AI_SEARCH]: this.$refs['ai-search-form'],
      };
      return mapping[tabId];
    },
    trackActivateTab(tab) {
      AnalyticsMainHandler.trackSearchTabClick(tab, getRoutePageName(this.$route));
    },
    mutateTabForm(mapping, formData, form) {
      if (!form) {
        return;
      }
      let newData = {};
      for (let [key, newKey] of Object.entries(mapping)) {
        newData[newKey] = this.getValue(newKey, formData[key]);
      }
      form.mutateFormData(newData);
      form.initSwitchLang();
    },
    getValue(newKey, newValue) {
      const method = this.getValueMapping[newKey];
      return method ? method(newValue) || newValue : newValue;
    },
    getCountryName(countryId) {
      const countries = this.searchAllRecordsOptionsState && this.searchAllRecordsOptionsState.source_countries;
      const country = countries ? countries.find(c => c.id === countryId) : null;
      return country && country.name;
    },
    getCountryId(countryName) {
      const countries = this.searchAllRecordsOptionsState && this.searchAllRecordsOptionsState.source_countries;
      const country = countries ? countries.find(c => c.name === countryName) : null;
      return country && country.id;
    },
    onSubmit({form, tab}) {
      if (tab === this.TAB_ID_SOURCES) {
        this.$store.commit('mutateSearchAllSourcesFormState', form);
      }
      this.$emit('submit', {tab});
    },
    onSubmitAiSearch() {
      this.$emit('submit-ai-search');
    },
    onValidationError(error) {
      this.$emit('validation-error', error);
    },
    onCancel() {
      this.$emit('cancel');
    },
    goToFullTextTab() {
      this.$refs.tabs.activateTab(TAB_ID_FULL_TEXT);
    },
  },
  components: {
    AiSearchForm,
    IndexedRecordsForm,
    FullTextForm,
    TabsBlock,
    TabsBlockItem,
    SourcesForm,
    AiIcon,
  },
  name: 'FormTabsBlockContainer',
};
</script>

<style scoped lang="scss">
.beta-disclaimer {
  background-color: $neutral-200;
  color: $neutral-500;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 16px;
  font-style: italic;

  &::v-deep a {
    color: $neutral-500;
  }
}

.tabs-block::v-deep .mobile-switch {
  display: none;
}

.tabs-block.ai::v-deep .tabs-content.stand-out {
  padding-bottom: 20px;
}
@media only screen and (max-width: $breakpoint-tablet) {
  .tabs-block.ai::v-deep .tabs-header {
    display: none;
  }

  .tabs-block::v-deep .tab.ai {
    display: none;
  }

  .tabs-block .mobile-switch {
    display: flex;
    border-radius: 4px;
    margin: 0 16px 24px 16px;
    $tab-color: $mcr-dark-grey;

    .tab {
      cursor: pointer;
      border: 1px solid $tab-color;
      color: $tab-color;
      margin-right: 0;
      border-radius: 0;
      padding: 8px 12px;
      flex-grow: 1;
      flex-basis: 0;
      text-align: center;
      align-items: center;
      justify-content: center;
      display: flex;

      .material-design-icon {
        margin-right: 8px;
      }

      &:first-of-type {
        border-radius: 4px 0 0 4px;
      }
      &:not(:first-of-type) {
        border-left: none;
      }
      &:last-of-type {
        border-radius: 0 4px 4px 0;
      }
      &.is-active {
        background-color: $tab-color;
        color: $off-white;
      }
    }
  }
}
</style>
