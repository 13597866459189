<template>
  <div class="ai-follow-up-form">
    <autosize-textarea
      :initial-text="text"
      :min-rows="1"
      :max-rows="2"
      :show-icon="true"
      class="filter"
      ref="textarea"
      placeholder="Ask follow-up"
      @keydown.enter.prevent="submit"
      @input="onInput"
    >
      <div class="submit-container" :class="{disabled: !text}" @click="submit">
        <bounce-loading class="dark" v-if="aiSearchFollowUpLoadingState"></bounce-loading>
        <send-icon v-else :size="24"></send-icon>
      </div>
    </autosize-textarea>
  </div>
</template>

<script>
import AutosizeTextarea from '@common/elements/inputs/AutosizeTextarea';
import BounceLoading from '@common/elements/loading-indicators/bounceLoading';
import {getRedirectRouteFromAiSearch} from '@common/pages/searches/helpers/utils';
import SendIcon from 'vue-material-design-icons/Send';
import {mapGetters} from 'vuex';

export default {
  data() {
    return {
      text: '',
    };
  },
  computed: {
    ...mapGetters(['aiSearchFollowUpLoadingState']),
  },
  methods: {
    onInput(event) {
      this.text = event.target.value;
    },
    submit() {
      if (!this.text || this.aiSearchFollowUpLoadingState) {
        return;
      }
      this.$store
        .dispatch('asAiFollowUpAction', {follow_up_prompt: this.text, search_url: window.location.href})
        .then(response => {
          this.processResponse(response);
        })
        .catch(() => {
          this.handleSearchError();
        });
    },
    processResponse(response) {
      if (!response.search_name) {
        return this.handleSearchError();
      }
      const route = getRedirectRouteFromAiSearch(response);
      if (!route) {
        return this.handleSearchError();
      }
      this.$emit('submit');
      this.clearText();
      this.$store.commit('setAiUsedRecentlyState', true);
      this.redirectToRoute(route);
    },
    handleSearchError() {
      this.$toasted.error(
        'No changes were made to your search results. Try adding more details, like a specific date, location, or relationship to refine your search.',
        {duration: 3000}
      );
    },
    clearText() {
      this.$refs.textarea.clearText();
    },
    redirectToRoute(route) {
      this.$router.push(route);
    },
  },
  name: 'AiFollowUpForm',
  components: {BounceLoading, AutosizeTextarea, SendIcon},
};
</script>

<style scoped lang="scss">
.ai-follow-up-form {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  padding: 12px 16px;
  display: flex;
  justify-content: center;
  z-index: 3;

  .filter {
    display: flex;
    max-width: 800px;
    flex-grow: 1;
    position: relative;
    textarea {
      flex-grow: 1;
    }
  }

  .submit-container {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 10px;
    right: 12px;
    color: $primary-600;
    cursor: pointer;

    &.disabled {
      color: $neutral-300;
    }
  }
}
</style>
