<template>
  <div class="section-more-records" v-show="results.length">
    <div class="text-lg">Similar Records Found</div>
    <div class="items-list">
      <section-more-records-item class="person-item" v-for="item in results" :item="item" :key="item.person.code">
      </section-more-records-item>
      <div class="footer" v-if="searchAllRoute">
        <router-link :to="searchAllRoute"
          >View {{ totalCount.toLocaleString('en') }} potential records matching "{{ fullName }}"</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import {TAB_ID_INDEXED_RECORDS} from '@common/utils/consts.search';
import {getSearchByProfileFilters} from '@common/utils/utils.routes';

import SectionMoreRecordsItem from './SectionMoreRecordsItem';

export default {
  props: {
    person: Object,
    fullNameEn: String,
    fullNameCh: String,
  },
  data() {
    return {
      results: [],
      totalCount: 0,
    };
  },
  created() {
    if (!this.filters || !this.person.user_has_full_access) {
      return;
    }
    this.$store.dispatch('searchAllRecordsSilentAction', this.filters).then(response => {
      const results = response.objects.filter(obj => obj.person.code !== this.person.code && obj.user_has_full_access);
      this.results = results.slice(0, 3);
      this.totalCount = response.meta.total_count - 1;
    });
  },
  computed: {
    filters() {
      return getSearchByProfileFilters(this.person);
    },
    fullName() {
      return this.fullNameEn || this.fullNameCh;
    },
    searchAllRoute() {
      if (this.filters) {
        let query = {...this.filters, tab: TAB_ID_INDEXED_RECORDS};
        return {name: 'search-all-records', query};
      }
    },
  },
  methods: {},
  name: 'SectionMoreRecords',
  components: {SectionMoreRecordsItem},
};
</script>

<style lang="scss" scoped>
.section-more-records {
  margin-bottom: 10px;
  .text-lg {
    color: $neutral-600;
    font-weight: 600;
    margin-bottom: 8px;
  }
  .items-list {
    border-radius: 4px;
    background: white;
  }
  .footer {
    padding: 10px 16px;
    font-weight: 500;
  }
}
</style>
